<template>
	<div class="thumbnail">
		<img alt="Mary Anning Reimagined" class="img-fluid mx-auto d-block" style="width:300px;" src="../assets/MAFrontCover3D.Transparent.png" />
	</div>
	<div>
		<div class="mb-2 text-nowrap">
			For <span style="color: var(--color-red);">DELIVERY IN NORTH AMERICA</span> - <a class="btn btn-danger" href="https://shop.minimuseum.com/mary-anning-she-sold-seashells" target="_blank" role="button">Order now</a>
		</div>

		<div class="mb-2 text-nowrap">
			For <span style="color: var(--color-blue);">DELIVERY IN AUSTRALASIA</span> - <a class="btn btn-primary" href="https://www.crystalworldsales.com/collections/books-and-cards/products/she-sold-seashells-the-curious-mary-anning-re-imagined-by-wolfgang-grulke" target="_blank" role="button">Order now</a>
		</div>

		<div class="mb-2 text-nowrap">
			For <span style="color: var(--color-darkgreen); ">DELIVERY IN THE UK</span> - <a class="btn btn-success" href="#z7" v-on:click="gotoPayPal()" role="button">Order now</a>
		</div>

		<div class="mb-2">
			For <span style="color: #ac2bac; ">DELIVERY IN THE EU</span> - <a class="btn text-white" style="background-color: #ac2bac;" href="https://www.amazon.co.uk/gp/product/1916039456" target="_blank" role="button">Order now</a> Shipped via <a href="https://amazon.co.uk/"  target="_blank">Amazon.co.uk</a>.
		</div>

		<p :class="IsPaypalVisible?'':'collapse'">
			&#163;12 each including postage. Pre-order quantities limited to a maximum of five.
			<br />
			<div style="padding-bottom:5px;">
				Number of copies: <select v-model="NumberOfCopies">
					<option disabled value="">Please select one</option>
					<option>1</option>
					<option>2</option>
					<option>3</option>
					<option>4</option>
					<option>5</option>
				</select>
			</div>

			<!--<div class="sheet-container">
			<div id="paypal-button-container"></div>
		</div>-->

			<div class="container d-flex align-items-center justify-content-center">
				<div id="paypal-buttons"></div>
			</div>
		</p>
		<p id="z7" class="pt-4">
			Please email <a href="maI&#108;&#116;&#111;&#58;Orders&#64;AtOne&#46;org">Orders&#64;AtOne&#46;org</a> for larger quantities.
		</p>
		<div ref="bottomDiv" class="mx-auto" style="max-width:50%">
			<img alt="Mary Anning" class="rounded shadow img-fluid mx-auto d-block" src="../assets/MAFrontCover.Preorder.png" />
		</div>
	</div>
</template>


<script>
	import { loadScript } from '@paypal/paypal-js';
	import Swal from "sweetalert2";
	export default {
		name: 'Order',

		data() {
			return {
				NumberOfCopies: 1,
				PricePerBook: 12,
				IsPaypalVisible: false,
			}
		},

		methods: {
			getPaypalAmountBreakdown() {
				return {
					item_total: {
						currency_code: "GBP",
						value: this.PricePerBook * this.NumberOfCopies
					},
					shipping: {
						currency_code: "GBP",
						value: 0
					}
				};
			},
			getPaypalAmount() {
				return {
					currency_code: "GBP",
					value: this.PricePerBook * this.NumberOfCopies,
					breakdown: this.getPaypalAmountBreakdown()
				}
			},
			getPaypalPurchaseUnits() {
				return [
					{
						amount: this.getPaypalAmount(),
						items: this.getPayPalItems()
					}
				];
			},
			getPayPalItems() {
				return [
					{
						name: this.NumberOfCopies == 1 ? "'She Sold Seashells ...and dragons' book" : "'She Sold Seashells ...and dragons' books",
						unit_amount: {
							currency_code: "GBP",
							value: this.PricePerBook
						},
						tax: {
							value: 0,
							currency_code: "GBP"
						},
						quantity: this.NumberOfCopies
					}
				];
			},
			gotoPayPal() {
				this.IsPaypalVisible = true;
				this.$refs.bottomDiv?.scrollIntoView({ behavior: "smooth", block: "end", inline: "nearest" });
			}
		},

		async mounted() {
			try {

				// Sandbox: 'AejD_ypJxs6dTJF61ey4b73vSekyMRxiLFWzrD1bdz9H8ck3eGKjB5CoCbzYHD07eI8mRNMJGuY8B1Tq',
				// To edit Paypal credit card statement reference, go to "Account settings" / "Website payments" / "Paypal buttons" / "Update" / "View your saved buttons" / "Manage checkout page styles"
				const paypal = await loadScript({
					'client-id': 'AdD46FKdLZKC8_lcVMR7tWze63es7L5WUwkuovleikWUz9rSdDlSht0-S-OwAfH_HCUH2BVAqU6Yg3IN',
					'currency': 'GBP'
				});

				var xxx = this;

				await paypal.Buttons({
					createOrder: function (data, actions) {
						return actions.order.create({
							purchase_units: xxx.getPaypalPurchaseUnits(),
							payee: {
								email_address: "orders@atone.org"
							},
							payee_display_metadata: {
								brand_name: "At One Communications"
							},
							application_context: {
								shipping_preference: "GET_FROM_FILE",
								business_name: "At One Communications",
							},
							note_to_payer: "Contact us at orders@atone.org for any questions on your order."

							//payment_source: {
							//	paypal: {
							//		experience_context: {
							//			payment_method_preference: "IMMEDIATE_PAYMENT_REQUIRED",
							//			payment_method_selected: "PAYPAL",
							//			brand_name: "At One",
							//			locale: "en-UK",
							//			landing_page: "LOGIN",
							//			shipping_preference: "SET_PROVIDED_ADDRESS",
							//			user_action: "PAY_NOW",
							//			return_url: "https://maryanningreimagined.org/OrderComplete",
							//			cancel_url: "https://maryanningreimagined.org/Order"
							//		}
							//	}
							//}
						});
					},
					onApprove: function (data, actions) {
						return actions.order.capture().then(function (orderData) {
							// Successful capture!
							Swal.fire({
								icon: "success",
								title: "Thank you!",
								text: "Your payment has successfully been proccessed.",
								confirmButtonText: "Close",
								//showLoaderOnConfirm: true,
								//preConfirm: () => {
								//	// redirect user to dashboard or login
								//	location.assign("http://localhost:8080/profile");
								//},
								allowOutsideClick: false
							});
						});
					},
					onAbort: function (data, actions) {
						Swal.fire({
							icon: "warning",
							title: "Aborted",
							text: "Transaction cancelled",
							confirmButtonText: "Close",
							allowOutsideClick: false
						});
					},
					onCancel: function (data, actions) {
						Swal.fire({
							icon: "warning",
							title: "Cancelled",
							text: "Transaction cancelled",
							confirmButtonText: "Close",
							allowOutsideClick: false
						});
					},
					style: {
						// Adapt to your needs
						layout: 'vertical',
						color: 'gold',
						shape: 'rect',
						label: 'paypal',
					},
					// The following is optional and you can
					// limit the buttons to those you want to
					// provide
					//fundingSource: paypal.FUNDING.PAYPAL,
				}).render('#paypal-buttons');
			} catch (error) {
				// Add proper error handling
				console.error(error);
			}
		}
	}
</script>

<style scoped>
</style>